exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abdiver-tsx": () => import("./../../../src/pages/abdiver.tsx" /* webpackChunkName: "component---src-pages-abdiver-tsx" */),
  "component---src-pages-abgenbank-tsx": () => import("./../../../src/pages/abgenbank.tsx" /* webpackChunkName: "component---src-pages-abgenbank-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-announcements-tsx": () => import("./../../../src/pages/announcements.tsx" /* webpackChunkName: "component---src-pages-announcements-tsx" */),
  "component---src-pages-antibody-analytics-tsx": () => import("./../../../src/pages/antibody-analytics.tsx" /* webpackChunkName: "component---src-pages-antibody-analytics-tsx" */),
  "component---src-pages-antibody-database-tsx": () => import("./../../../src/pages/antibody-database.tsx" /* webpackChunkName: "component---src-pages-antibody-database-tsx" */),
  "component---src-pages-antibody-discovery-platform-tsx": () => import("./../../../src/pages/antibody-discovery-platform.tsx" /* webpackChunkName: "component---src-pages-antibody-discovery-platform-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-grants-antibody-specificity-extraction-tsx": () => import("./../../../src/pages/grants/antibody-specificity-extraction.tsx" /* webpackChunkName: "component---src-pages-grants-antibody-specificity-extraction-tsx" */),
  "component---src-pages-grants-iseat-tsx": () => import("./../../../src/pages/grants/iseat.tsx" /* webpackChunkName: "component---src-pages-grants-iseat-tsx" */),
  "component---src-pages-grants-specitope-tsx": () => import("./../../../src/pages/grants/specitope.tsx" /* webpackChunkName: "component---src-pages-grants-specitope-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-nanobodies-tsx": () => import("./../../../src/pages/nanobodies.tsx" /* webpackChunkName: "component---src-pages-nanobodies-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-ngs-tsx": () => import("./../../../src/pages/ngs.tsx" /* webpackChunkName: "component---src-pages-ngs-tsx" */),
  "component---src-pages-pad-tsx": () => import("./../../../src/pages/pad.tsx" /* webpackChunkName: "component---src-pages-pad-tsx" */),
  "component---src-pages-paired-ngs-tsx": () => import("./../../../src/pages/paired-ngs.tsx" /* webpackChunkName: "component---src-pages-paired-ngs-tsx" */),
  "component---src-pages-resources-research-space-index-tsx": () => import("./../../../src/pages/resources/research-space/index.tsx" /* webpackChunkName: "component---src-pages-resources-research-space-index-tsx" */),
  "component---src-pages-resources-year-index-tsx": () => import("./../../../src/pages/resources/year/index.tsx" /* webpackChunkName: "component---src-pages-resources-year-index-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-therapeutics-tsx": () => import("./../../../src/pages/therapeutics.tsx" /* webpackChunkName: "component---src-pages-therapeutics-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-use-case-sanity-usecases-slug-current-tsx": () => import("./../../../src/pages/use-case/{SanityUsecases.slug__current}.tsx" /* webpackChunkName: "component---src-pages-use-case-sanity-usecases-slug-current-tsx" */)
}

